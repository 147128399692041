// import * as math from 'mathjs';

export default class Queue {
  #index = 0;
  constructor(capacity = 5, initialValue = null) {
    this.capacity = capacity;
    this.storage = new Array();

    if (initialValue !== null && initialValue !== undefined) {
      this.fillUp(initialValue);
    }
  }

  enqueue(val, options = {}) {
    const { fill } = options;
    // this.storage.push(val);

    this.storage[this.#index] = val;
    ++this.#index;

    if (this.#index >= this.capacity) {
      this.#index = 0;
    }

    if (fill) {
      this.fillUp(val);
    }

    // if (this.storage.length > this.capacity) {
      // this.storage.shift();
    // }
  }

  fillUp(val) {
    while (this.storage.length < this.capacity) {
      this.storage.push(val);
    }
  }

  values() {
    return this.storage;
  }

  every(fn) {
    return this.storage.every(fn);
  }

  /*
  last() {
    return this.storage[this.storage.length - 1];
  }

  previous() {
    return this.storage[this.storage.length - 2];
  }

  variance() {
    return math.variance(this.values());
  }

  */

  average() {
    // return math.mean(this.values());
    return (this.storage.reduce((sum, val) => sum + val, 0) / this.capacity);
  }

  std() {
    const mean = this.average();
    return Math.sqrt(this.storage.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / this.capacity)
    // return math.std(this.values());
  }

  get empty() {
    return this.storage.length === 0;
  }
}
