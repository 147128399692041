import StaticGrid from "@/../api/v3/StaticGrid";
import Mirror from "@/../api/v3/Mirror";

const sceneParams = {

  awake: {
    backgroundColor: "#BACDCF",
    lineColor: "#fff",
    gridColor: "#ffffff22",
  },

  backgroundColor: "#000",
  // backgroundColor: "#BACDCF",
  clockColor: "#FFFFFF",
  colorPrimary: "#FFFFFF",
  colorSecondary: "#019966",

  panelBackground: "#601E00DD",
  appsBackground: "#4A1700DD",

  panelVibrant: "#9A1700",
  panelSeparator: "#fff",

  blackOnWhite: false,
  AudioInput: false,
  AudioPlayer: true,

  frameRate: 30,
};

const matrices = {
  StaticGrid: {
    class: StaticGrid,
    settings: {},
    params: {
      lineColor: "#000",
      // lineColor: "#ffffff22",

      offsetX: 0,
      offsetY: 0,
      scale: 1,
      cellW: 15,
      cellH: 180,
      linePadding: 1,
      lineWeight: 0.5,
    },
  },
  Mirror: {
    class: Mirror,
    settings: {},
    params: {
      offsetX: 0,
      offsetY: 0,
      // lineColor: "#000",
      lineColor: "#ffffff",

      lineWeight: 0.25,
      maxLineWeight: 0.25,
      weightVariation: true,

      horizMovement: 1,
      vertMovement: 0,
      inactivationThreshold: 1,
      sensibility: 20,
      scale: 1,
      cellW: 30,
      cellH: 360,
      linePadding: 2,
    },
  },
};

const theme = {
  sceneParams,
  matrices,
};
export default theme;
