import StaticGrid from '@/../api/v3/StaticGrid';
import Mirror from '@/../api/v3/Mirror';

const sceneParams = {

  awake: {
    backgroundColor: "#444807",
    lineColor: "#969B58",
    gridColor: "#75793D",
  },

  backgroundColor: "#000",
  // backgroundColor: "#444807",

  clockColor: "#969B57",
  panelBackground: "#383924",
  appsBackground: "#2B2C1F",
  colorPrimary: "#EBEBEB",
  colorSecondary: "#000",
  panelVibrant: "#969B57",
  panelSeparator: "#000",

  blackOnWhite: false,
};

const matrices = {
  StaticGrid: {
    class: StaticGrid,
    settings: {},
    params: {
      lineColor: "#000",
      // lineColor: "#75793D",
      offsetX: 0,
      offsetY: 0,
      scale: 1,
      cellW: 15,
      cellH: 45,
      linePadding: 5,
      lineWeight: 0.5,
    },
  },
  Mirror: {
    class: Mirror,
    settings: {},
    params: {
      offsetX: 0,
      offsetY: 0,
      // lineColor: "#000",
      lineColor: "#969B58",
      lineWeight: 0.25,
      maxLineWeight: 0.25,
      weightVariation: true,
      horizMovement: 1,
      vertMovement: 0,
      inactivationThreshold: 1,
      sensibility: 20,
      scale: 1,
      cellW: 30,
      cellH: 180,
      linePadding: 2,
    },
  },
};

const theme = {
  sceneParams,
  matrices,
}
export default theme;
