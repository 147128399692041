<template>
  <div @click="clicked">
    <figure v-if="appearance === 'picture'">
      <img src="@/assets/mirror.png">
      <!-- <img src="@/assets/matrix.png"> -->
    </figure>
    <div v-if="appearance === 'color'" class="Mirror-ColorBackground"></div>
    <section v-else id="canvas-container" class="CanvasContainer">
      <slot></slot>
    </section>
  </div>
</template>

<script>
import SceneState from "@/../api/v3/SceneState";
import CellActivationQueue from "@/concerns/CellActivationQueue";
import P5Canvas from "@/concerns/P5Canvas";

export default {
  name: "Mirror",
  props: {
    appearance: {
      default: "picture",
      validator: val => ["canvas", "picture", "color"].indexOf(val) !== -1,
    },
    screenMode: {
      type: Object,
      required: true,
    },
    theme: {
      type: Object,
      required: true,
    },
  },
  watch: {
    theme(newValue) {
      if (this.appearance !== "canvas") {
        return;
      }
      const state = new SceneState(newValue);
      this.canvas.updateState(state);
      this.screenMode.update({ duration: 0.1 });
    },
  },
  mounted() {
    if (this.appearance !== "canvas") {
      return;
    }
    const state = new SceneState(this.theme);
    this.canvas = new P5Canvas(state, 'canvas-container', this);
    this.activatedCellsQueue = new CellActivationQueue(60, 0);
    window.addEventListener("resize", this.debouncedResize);
    window.setTimeout(() => {
      this.activatedCellsQueue.cellTotal = window.state.matrixNamed("Mirror").cells.length;
    }, 250);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.debouncedResize);
  },
  methods: {
    debouncedResize() {
      // console.log('debouncedResize');
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(this.reload, 300);
    },
    reload() {
      // console.log('reloaded');
      if (this.appearance !== "canvas") {
        return;
      }
      const state = new SceneState(this.theme);
      this.canvas.updateState(state);
      this.activatedCellsQueue.cellTotal = window.state.matrixNamed("Mirror").cells.length;
      window.scrollTo(0, 0);
    },
    clicked() {
      this.$emit("activity");
      // this.reload();
    },
    tick() {
      if (state.activeCells) {
        this.activatedCellsQueue.enqueue(state.activeCells);
      }
      if (state.instance.frameCount % 15 === 0) {
        this.$emit("density", this.activatedCellsQueue.context());
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Mirror,
.Mirror-ColorBackground,
figure {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Mirror-ColorBackground {
  background-color: #8B2D01;
}
figure {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
<style>
  .CanvasContainer canvas {
    height: 100vh;
    width: 100vw;
  }
</style>
