<template>
  <main :style="stylingProperties">
    <Mirror ref="mirror" class="Mirror"
            :theme="theme" :screen-mode="screenMode"
            appearance="canvas"
            @activity="mirrorActivity"
            @density="mirrorDensityChanged"
    />

    <!--
      <Interface ref="interface" class="Interface"
               :device="deviceType" :screen-mode="screenMode" :theme="theme"
               @activity="interfaceActivity"
      />
    -->
    <div class="Utilities">
      <!-- <ColorBar class="ColorBar" :screen-mode="screenMode" /> -->
      <ThemePicker class="ThemePicker" :themes="Object.keys(Themes)" @update-theme="setTheme" />
    </div>
    <!-- <FaceDetector @changed="detectorChanged" @eyes-position="eyesMoved" /> -->
  </main>
</template>

<script>
import Mirror from "./components/Mirror.vue";
// import Interface from "./Interface.vue";
import ThemePicker from "./components/ThemePicker.vue";
// import ColorBar from "./components/ColorBar.vue";
// import FaceDetector from "./components/FaceDetector";

import Themes from "./Themes";
import ScreenMode from "./concerns/ScreenMode.ts";

import throttle from "lodash.throttle";

export default {
  components: {
    Mirror,
    // Interface,
    ThemePicker,
    // ColorBar,
    // FaceDetector,
  },
  data() {
    return {
      deviceType: "none",
      Themes,
      theme: Themes.redOnRed,
      screenMode: new ScreenMode(),
      eyeX: 0,
      eyeY: 0,
    };
  },
  computed: {
    stylingProperties() {
      return {
        "--color-clock": this.theme.sceneParams.clockColor,
        "--color-main-background": this.theme.sceneParams.awake.backgroundColor,
        "--color-panel-background": this.theme.sceneParams.panelBackground,
        "--color-apps-background": this.theme.sceneParams.appsBackground,
        "--color-primary": this.theme.sceneParams.colorPrimary,
        "--color-secondary": this.theme.sceneParams.colorSecondary,
        "--color-vibrant": this.theme.sceneParams.panelVibrant,
        "--color-panel-separator": this.theme.sceneParams.panelSeparator,
        "--separator-height": "3px",
        "--value-height": "0.5px",
        "--shadow-x": `${this.eyeX * 25}px`,
        "--shadow-y": `${this.eyeY * 25}px`,
      };
    },
  },
  mounted() {
    const savedThemeName = this.$cookies.get("theme-name");
    if (savedThemeName && Themes[savedThemeName]) {
      this.theme = Themes[savedThemeName];
    }
    this.throttledActivityFn = throttle(() => {
      this.screenMode.tapEvent();
    }, 200, { leading: true, trailing: true });
  },
  methods: {
    setDevice(device) {
      setTimeout(() => { this.$refs.interface.deviceChanged() }, 100);
      this.deviceType = device;
    },
    setTheme(themeName) {
      this.theme = Themes[themeName];
      this.$cookies.set("theme-name", themeName);
    },
    mirrorActivity() {
      // this.screenMode.tapEvent();

      // débrayage complet pour version miroir.
      this.screenMode.auto = false;
      switch(this.screenMode.mode) {
        case "asleep":
        case "waking":
          this.screenMode.setMode("awake");
          break;
        case "awake":
          this.screenMode.setMode("waking");
      }
    },
    interfaceActivity() {
      this.throttledActivityFn();
    },
    mirrorDensityChanged(density) {
      if (!this.screenMode.auto) {
        return;
      }
      this.screenMode.cellEvent(density);
    },
    detectorChanged(context) {
      return;
      if (!this.screenMode.auto) {
        return;
      }
      this.screenMode.faceEvent(context);
    },
    eyesMoved({ x, y }) {
      if (this.screenMode.mode !== "awake") { return; }
      this.eyeX = x;
      this.eyeY = y;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/fonts.scss";
@import "@/assets/panel.scss";
main {
  position: relative;
  height: 100vh;
}
.Mirror {
  position: absolute;
}
.Utilities {
  position: fixed;
  bottom: .25rem;
  right: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Interface {
  width: 100%;
  height: 100%;
  position: relative;
}

</style>
