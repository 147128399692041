import Matrix from './Matrix';
import Cell from './Cell';
import * as Method from './TintMethods';

export default class StaticGrid extends Matrix {
  constructor(name, params, active = true) {
    super(name, params, active);
    // this.scopedParams = ["w", "h", "lineColorDay", "lineColorNight", "offsetX", "offsetY", "cellW", "cellH", "scale"];
  }

  initialize() {
    this.configure();
  }

  deinit() {}

  configure() {
    const p = state.instance;
    this.cells = [];

    let cellOuterWidth = this.params.cellW * this.params.scale;
    let cellOuterHeight = this.params.cellH * this.params.scale;
    let numCol = Math.ceil(p.width / cellOuterWidth + 1);
    let numRow = Math.ceil(p.height / cellOuterHeight + 1);

    state.current = this.params;
    this.currentColor = state.lineColor();

    for (let row = 0; row < numRow; row++) {
      for (let col = 0; col < numCol; col++) {
        let x = col * cellOuterWidth + this.params.offsetX;
        let y = row * cellOuterHeight + this.params.offsetY;
        let cell = new Cell(x, y, row, col);
        cell.tintMethod = Method.STATICGRID;
        cell.input(this.currentColor);
        this.cells.push(cell);
      }
    }
  }

  tick() {
    const p = state.instance;
    if (p) {
      p.background(255);
    }
    if (state.lineColor() !== this.currentColor) {
      this.currentColor = state.lineColor();
      this.cells.forEach(c => c.input(this.currentColor));
    }
  }

  paintSource() {}
}
