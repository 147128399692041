import StaticGrid from '@/../api/v3/StaticGrid';
import Mirror from '@/../api/v3/Mirror';

const sceneParams = {

  awake: {
    backgroundColor: "#DAE8EA",
    lineColor: "#7AC6D3",
    gridColor: "#fff",
  },

  backgroundColor: "#000",
  // backgroundColor: "#DAE8EA",

  clockColor: "#7BC6D3",
  panelBackground: "#D2E1E3",
  appsBackground: "#738689",
  colorPrimary: "#000000",
  colorSecondary: "#7BC6D3",
  panelVibrant: "#738689",
  panelSeparator: "#000",

  blackOnWhite: true,
};

const matrices = {
  StaticGrid: {
    class: StaticGrid,
    settings: {},
    params: {
      lineColor: "#000",
      // lineColor: "#fff",
      offsetX: 7,
      offsetY: 0,
      scale: 1,
      cellW: 20,
      cellH: 45,
      linePadding: 5,
      lineWeight: 1,
    },
  },
  Mirror: {
    class: Mirror,
    settings: {},
    params: {
      offsetX: 0,
      offsetY: 0,
      // lineColor: "#000",
      lineColor: "#7AC6D3",
      lineWeight: 0.25,
      maxLineWeight: 0.25,
      weightVariation: true,
      horizMovement: 0.5,
      vertMovement: 0,
      inactivationThreshold: 1,
      sensibility: 20,
      scale: 1,
      cellW: 20,
      cellH: 90,
      linePadding: 2,
    },
  },
};

const theme = {
  sceneParams,
  matrices,
}
export default theme;
