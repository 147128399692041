import Queue from './Queue';
// import ColorQueue from './ColorQueue';
import * as Method from './TintMethods';

function map(...args) {
  return state.instance.map(...args);
}

/* eslint-disable no-param-reassign */
export default class Cell {
  constructor(x, y, row, col) {;
    const p = state.instance;

    this.clear();
    this.x = x;
    this.y = y;
    this.column = col,
    this.row = row;

    this.data = {};

    this.inputColor = p.color(0, 0, 0, 0);
    this.colorHistory = new Queue(state.current.sensibility);
  }

  clear() {
    this.previousState = Object.assign({}, this.render);
    this.render = {
      color: this.inputColor,
      weight: state.current.lineWeight * state.current.scale,
      xOffset: 0,
      yOffset: 0,
      active: false
    };
  }

  activate()   {
    this.render.active = true;
    ++state.activeCells;
  }
  deactivate() {
    this.render.active = false;
  }

  input(inputColor) {
    const p = state.instance;

    if (inputColor === undefined) {
      inputColor = this.render.color || p.color(0, 0, 0, 0);
    } else if (!inputColor.mode) {
      inputColor = p.color(inputColor);
    }

    this.inputColor = inputColor;
    this.colorHistory.enqueue(p.brightness(inputColor), { fill: true });

    this.clear();
    this.tint();
  }

  tint() {
    switch (this.tintMethod) {
      case Method.MIRROR:
        this.mirrorTint();
        break;
      case Method.STATICGRID:
      default:
        this.uniformTint();
        break;
    }
  }

  defaultTint() {
    let brightnessDeviation = this.brightnessDeviation();
    let averageBrightness = this.averageBrightness();

    if (brightnessDeviation > state.current.inactivationThreshold) {
      this.activate();
    } else {
      this.deactivate();
    }

    this.render.xOffset = averageBrightness;
    this.render.yOffset = map(brightnessDeviation, 0, 2, -(state.current.vertMovement || 0), (state.current.vertMovement || 0));

    this.render.weight = state.current.lineWeight * state.current.scale;
    if (state.current.weightVariation) {
      this.render.weight *= (brightnessDeviation - state.current.inactivationThreshold);
    }
  }

  mirrorTint() {
    const p = state.instance;

    this.defaultTint();
    const col = p.color(state.lineColor());
    this.render.color = col;

    // if (p.frameCount % 30 === 0 && this.x < 10 && this.y < 10) {
      // console.log(this.render);
    // }
  }

  uniformTint() {
    this.render.active = true;
    this.render.weight = state.current.lineWeight;
    this.render.color = state.lineColor();
  }

  brightnessDeviation() {
    if (this.colorHistory.empty) { return 1; }
    return map(this.colorHistory.std(), 0, 5, 0, 2);
  }

  averageBrightness() {
    if (this.inputColor === undefined) { return 0; }
    // if (this.inputColor === undefined || alpha(this.inputColor) === 0) { return 0; }
    const average = this.colorHistory.average();
    return (average / 100) * (state.current.horizMovement);
  }

  draw() {
    const p = state.instance;

    let { x, y } = this;
    let { weight, xOffset, yOffset, active } = this.render;
    let cellColor = this.render.color;

    if (!active || !cellColor || p.alpha(cellColor) === 0) {
      return;
    }
    // if (frameCount === 80 && alpha(cellColor) < 10 && this.tintMethod === MIRROR) {
    //   console.log(alpha(cellColor), this);
    // }

    // p.push();
    let w = state.current.cellW * state.current.scale;
    let h = state.current.cellH * state.current.scale;
    let padding = h * (state.current.linePadding / 100)

    x += w * xOffset;
    y += yOffset;

    p.strokeCap(p.SQUARE);
    p.strokeWeight(weight);

    p.stroke(cellColor);
    p.line(
      x + w / 2.0, y + padding,
      x + w / 2.0, y + h - padding
    );

    if (this.debug) {
      p.noStroke();
      p.textSize(6);
      p.fill(255);
      p.text(`${this.row}\n${this.column}`, x + 2, y + 2);
    }

    // p.pop();
  }
}
