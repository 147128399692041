export default class Matrix {
  constructor(name, params, active = true) {
    this.name = name;
    this.params = params;
    // this.cachedParams = Object.assign({}, params);
    // this.scopedParams = [];

    this.active = active;
    this.cells = [];
  }

  deinit() {}
  mouseReleased() {}
  paintSource() {}
  paintOverlay() {}
}
