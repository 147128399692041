export default class SceneState {

  constructor({ matrices, sceneParams }) {
    this.params = Object.assign({}, sceneParams);
    this.availableMatrices = Object.assign({}, matrices);

    this.matrices = []; // filled in P5Canvas#makeInstance
    this.current = {};

    this.setSharedSettings();
  }

  setSharedSettings() {
    this.w = window.innerWidth;
    this.h = window.innerHeight;
    this.frameRate = 30;

    this.params.alpha = 255;
  }

  active() {
    return this.matrices.filter(m => m.active);
  }

  setCurrentMatrix(m) {
    this.currentMatrix = m.name;
    this.current = m.params;
  }

  matrixNamed(name) {
    return this.matrices.find(m => m.name === name);
  }

  backgroundColor() {
    const p = this.instance;
    if (!this.cachedBackgroundColor) {
      this.cachedBackgroundColor = p.color(this.params.backgroundColor);
    }
    return this.cachedBackgroundColor;
  }

  lineColor() {
    const p = this.instance;
    if (!this["cachedLineColor" + this.currentMatrix]) {
      this["cachedLineColor" + this.currentMatrix] = p.color(this.current.lineColor);
    }
    return this["cachedLineColor" + this.currentMatrix];
  }

  invalidateParams() {
    this.matrices.forEach(m => {
      m.dirty = true;
    });
  }

  // SMH
  setBackgroundColor(newColor, lerpAmount = 1) {
    this.updateColor(null, "backgroundColor", "cachedBackgroundColor", "initialBackgroundColor", newColor, lerpAmount);
  }

  setLineColor(newColor, lerpAmount = 1) {
    this.updateColor("Mirror", "lineColor", "cachedLineColorMirror", "initialLineColor", newColor, lerpAmount);
  }

  setGridColor(newColor, lerpAmount = 1) {
    this.updateColor("StaticGrid", "lineColor", "cachedLineColorStaticGrid", "initialGridColor", newColor, lerpAmount);
  }

  resetInitialColors() {
    this.initialBackgroundColor = null;
    const matrices = {
      Mirror: this.matrixNamed("Mirror"),
      StaticGrid: this.matrixNamed("StaticGrid"),
    };
    if (matrices.Mirror) { matrices.Mirror.initialLineColor = null };
    if (matrices.StaticGrid) { matrices.StaticGrid.initialGridColor = null };
  }

  // SMH SMH
  updateColor(matrix, valueName, cachedValueName, initialValueName, newColor, lerpAmount) {
    if (lerpAmount !== 1) {
      const p = this.instance;
      if (!p.push) { return; }
      p.push();
      p.colorMode(p.RGB);
      if (matrix) {
        this[initialValueName] ||= p.color(this.matrixNamed(matrix).params[valueName]);
        this.matrixNamed(matrix).params[valueName] = p.lerpColor(this[initialValueName], p.color(newColor), lerpAmount);
      } else {
        this[initialValueName] ||= p.color(this.params[valueName]);
        this.params[valueName] = p.lerpColor(this[initialValueName], p.color(newColor), lerpAmount);
      }
      p.pop();
    } else {
      this[initialValueName] = null;
      if (matrix) {
        this.matrixNamed(matrix).params[valueName] = newColor;
      } else {
        this.params[valueName] = newColor;
      }
    }
    this[cachedValueName] = null;

  }
}
