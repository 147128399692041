const MIRROR = "kMirror";
const STATICGRID = "kStaticGrid";

export { MIRROR, STATICGRID };

// const OVERLAY = "kOverlay";
// const PALETTE = "kPalette";
// const EQ = "kEQ";
// const BGGRID = "kGrid";
// const DIRECT = "kDirect";
// const RAWTINT = "kRaw";
// const NOISY = "kNoisy";
//
// export { OVERLAY, PALETTE, MIRROR, EQ, BGGRID, DIRECT, RAWTINT, NOISY, STATICGRID };
