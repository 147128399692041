import Queue from '../../api/v3/Queue';

export default class CellActivationQueue extends Queue {
  cellTotal = 100;

  #threshold = 0.025;
  // #largePct = 0.1;
  // #mediumPct = 0.025;

  constructor(capacity, initialValue) {
    super(capacity, initialValue);
  }

  context() {
    // if (this.large()) {
      // return "large";
    // }
    // if (this.medium()) {
    //   return "medium";
    // }
    if (this.dense()) {
      return "dense";
    }
    if (this.empty()) {
      return "empty";
    }
    return "sparse";
  }

  dense() {
    return this.every(v => v > this.cellTotal * this.#threshold);
  }

  // large() {
  //   return this.every(v => v > this.cellTotal * this.#largePct);
  // }
  //
  // medium() {
  //   return this.every(v => v > this.cellTotal * this.#mediumPct);
  // }

  empty() {
    return this.every(v => v === 0);
  }
}
