import whiteOnBlue from "@/configurations/white-on-blue";
import blueOnBlue from "@/configurations/blue-on-blue";
import yellowOnGreen from "@/configurations/yellow-on-green";
import redOnRed from "@/configurations/red-on-red";

const Themes = Object.freeze({
  whiteOnBlue: Object.freeze(whiteOnBlue),
  blueOnBlue: Object.freeze(blueOnBlue),
  yellowOnGreen: Object.freeze(yellowOnGreen),
  redOnRed: Object.freeze(redOnRed),
});

function setTheme(theme) {
  this.$emit("set-theme", theme);
}

export { setTheme };
export default Themes;
