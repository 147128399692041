import StaticGrid from '@/../api/v3/StaticGrid';
import Mirror from '@/../api/v3/Mirror';

const sceneParams = {

  awake: {
    backgroundColor: "#8B2D01",
    lineColor: "#FF5100",
    gridColor: "#C1470E",
  },

  backgroundColor: "#000",
  // backgroundColor: "#8B2D01",

  clockColor: "#FF5100",
  panelBackground: "#601E00DD",
  appsBackground: "#4A1700DD",
  colorPrimary: "#EBEBEB",
  colorSecondary: "#000",

  panelVibrant: "#9A1700",
  panelSeparator: "#000",

  blackOnWhite: false,

  frameRate: 30,
};

const matrices = {
  StaticGrid: {
    class: StaticGrid,
    settings: {},
    params: {
      lineColor: "#00000000",
      // lineColor: "#C1470E",

      offsetX: 0,
      offsetY: 0,
      scale: 1,
      cellW: 15,
      cellH: 180,
      linePadding: 1,
      lineWeight: 0.5,
    },
  },
  Mirror: {
    class: Mirror,
    settings: {},
    params: {
      offsetX: 0,
      offsetY: 0,
      // lineColor: "#000",
      lineColor: "#FF5100",

      lineWeight: 0.25,
      maxLineWeight: 0.25,
      weightVariation: true,

      horizMovement: 1,
      vertMovement: 0,
      inactivationThreshold: 1,
      sensibility: 20,
      scale: 1,
      cellW: 30,
      cellH: 360,
      linePadding: 2,
    },
  },
};

const theme = {
  sceneParams,
  matrices,
}
export default theme;
